<template>
  <div class="d-flex align-center justify-space-between scale-1">
    <v-btn
      depressed
      class="rounded-r-0"
      style="width: 32px; min-width: 32px"
      color="counter"
      light
      @click="minusNumber(BirimRitim)"
    >
      <v-icon small :color="number == 0 ? 'primary' : 'error'">
        {{ icons.mdiMinusThick }}
      </v-icon>
    </v-btn>
    <v-text-field
      ref="input"
      v-model.number="number"
      v-number-only
      :suffix="Birim"
      hide-details
      style="max-width: 80px"
      solo
      background-color="counter"
      light
      type="tel"
      flat
      dense
      :disabled="disabled"
      class="right-input rounded-0 font-weight-medium night--text"
      @blur="onBlurNumber"
      @focus="$event.target.select()"
    />

    <v-sheet class="d-flex" :color="BgColor">
      <v-btn
        depressed
        class="rounded-l-0"
        :disabled="disabled"
        style="width: 32px; min-width: 32px"
        :color="number == 0 ? 'nightDark' : plantId == '01' ? 'secondary' : 'warning'"
        @click="addNumber(BirimRitim)"
      >
        <v-icon small>
          {{ icons.mdiPlusThick }}
        </v-icon>
      </v-btn>
    </v-sheet>
  </div>
</template>

<script>
import store from '@/store'
import { mdiMinusThick, mdiPlusThick } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  directives: {
    'number-only': {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      },
    },
    'letter-only': {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }

          return true
        }
        el.addEventListener('keypress', checkValue)
      },
    },
  },
  props: {
    paymCond: {
      type: String,
    },
    bgColor: {
      type: String,
      default: 'secondary',
    },
    uid: {
      default: null,
    },
    plantId: {
      type: String,
      default: '01',
    },

    buttonColor: {
      type: String,
      default: 'white',
    },
    birimRitim: {
      type: Number,
      default: 50,
    },
    birim: {
      type: String,
      default: 'Ad',
    },
    miktar: {
      type: Number,
      default: 0,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'refresh'],
  setup(props, { emit }) {
    const disabled = ref(false)
    const number = ref(0)
    const Birim = ref(props.birim)
    const BirimRitim = ref(props.birimRitim)
    const BgColor = ref(props.bgColor)
    const ButtonColor = ref(props.buttonColor)
    const refresh = () => {
      emit('refreshBasket')
    }
    const addBasket = qty => {
      disabled.value = true
      if (qty === 0) {
        const id = props.uid
        const plant = props.plantId

        store.dispatch('app-order/fetchDeleteBasketItem', { id, plant }).then(response => {
          if (response.error) {
          } else {
            refresh()
          }
        })
        setTimeout(() => {
          disabled.value = false
        }, 300)
      } else {
        let veriler = {}

        if (props.paymCond) {
          veriler = { method: 'addToBasket', uid: props.uid, qty, paymCond: props.paymCond, plant: props.plantId }
        } else {
          veriler = {
            method: 'addToBasket',
            uid: props.uid,
            qty,
            plant: props.plantId,
          }
        }
        store.dispatch('app-order/fetchAddToBasket', veriler).then(response => {
          if (response.error) {
          } else {
            refresh()
          }
        })
        setTimeout(() => {
          disabled.value = false
        }, 300)
      }
    }

    const addNumber = val => {
      number.value += Number(val) - Number(number.value % val)
      addBasket(number.value)
      select()
    }

    number.value = Number(props.miktar)

    watch(
      () => props.miktar,
      (first, second) => {
        number.value = Number(props.miktar)
      },
    )

    const select = () => {
      emit('selected', { number: number.value, uid: props.uid, plantId: props.plantId })
    }

    const onBlurNumber = () => {
      addBasket(number.value)
      select()
    }

    // eslint-disable-next-line no-return-assign
    const minusNumber = val => {
      if (number.value == 0) {
        return true
      }
      if (number.value < 50) {
        number.value = 0
        addBasket(0)

        select()

        return true
      }

      number.value != 0
        ? (number.value -= Number(number.value % val) === 0 ? Number(val) : Number(number.value % val))
        : null
      addBasket(number.value)
      select()
    }

    return {
      Birim,
      number,
      select,
      BgColor,
      ButtonColor,
      BirimRitim,
      addNumber,
      minusNumber,
      disabled,
      onBlurNumber,

      icons: {
        mdiPlusThick,
        mdiMinusThick,
      },
    }
  },
}
</script>

<style lang="scss">
.scale-1 {
  transform: scale(0.85);
  transform-origin: center right;
}
.completed {
  label,
  .v-text-field__suffix,
  input {
    color: #3b355a !important;
    text-shadow: none !important;
  }
}
.right-input {
  input {
    text-align: right;
  }
}
</style>
